import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{color:"green", textAlign:'center', }}>PAGE NOT FOUND</h1>
    <div style={{textAlign: 'center'}}>tsk, tsk...</div>
  </Layout>
)

export default NotFoundPage
